#productslide .slick-track,
#sliderSyncingThumb .slick-track {

  margin-left: 0px !important;
  margin-right: 0px !important;
}




.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #114232;
}


.block-product-docs  .tab-inside p {
    margin-bottom:0px!important;
}
