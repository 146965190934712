@include media-breakpoint-down(xs) {  }
@include media-breakpoint-down(sm) {

  .page-header h2 {
    font-size: 1.28em !important;
  }

 }
@include media-breakpoint-down(md) {

  .page-header h2 {
    font-size: 1.48em !important;
  }

  }
@include media-breakpoint-down(lg) {  }
