
body {
  background-color:#FBFBFB !important;
  background:#FBFBFB !important;

}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $primary;
}

/*------------------------------------
  BG COLORS
------------------------------------*/

.bg-light {
  background-color:#FBFBFB !important;
  background:#FBFBFB !important;
}

.bg-dark-green {
  background-color:#2E5347 !important;
  background:#2E5347 !important;
}

/*------------------------------------
  BORDERS
------------------------------------*/
.border-dotted-bottom {
border-bottom: 3px dotted #969696;
}

.border-dotted-left {
border-left: 3px dotted #969696;
}

.border-dotted-right {
border-right: 3px dotted #969696;
}



.btn-text {


  color: #404040;
    text-decoration: underline;

  &:hover {
  color: $primary;
  text-decoration: underline;
  }

  &:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $primary;    }
  }
}


// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
  .page-hero {

    min-height:260px;


    h2 {
      font-size:1.8em;
    }
    p {

    font-size:1.2em;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .page-hero {
    min-height:360px;
      height:360px;

    h2 {
      font-size:2.4em;
    }
    p {

    font-size:1.8em;
    }

  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .page-hero {
    min-height:400px;
  height:400px;
    h2 {
      font-size:2.4em;
    }
    p {

    font-size:1.8em;
    }

  }


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .page-hero {
  min-height:400px;
  height:400px;



    h2 {
      font-size:2.4em;
    }
    p {

    font-size:1.8em;
    }

  }



 }

 .gradient-hero {
   min-height:280px !important;
 }

 // Small devices (landscape phones, 576px and up)
 @media (max-width: 576px) {
   .hp-block  {
    height: 100%;


  }
 }

 // Medium devices (tablets, 768px and up)
 @media (min-width: 992px) {

   .hp-block  {
    height: 400px;


  }
  }


  p a,
  a a:link {
    color:$orange !important;
    text-decoration: underline !important;
cursor: pointer;
    &:hover {
    color: $orange-darker !important;
    text-decoration: underline !important;
    }

    &:not(:disabled):not(.disabled),
    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $orange-darker !important;
    }

  }
    }

    a, .btn {
      cursor: pointer !important;
    }
