  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {

    // .block-cta h2 {
    //   font-size: 1.28em !important;
    // }

   }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {



   }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {



  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {


   }


   @include media-breakpoint-down(xs) {  }
   @include media-breakpoint-down(sm) {

     .block-cta h2 {
       font-size: 1.28em !important;
     }

    }
   @include media-breakpoint-down(md) {

     .block-cta h2 {
       font-size: 1.48em !important;
     }

     }
   @include media-breakpoint-down(lg) {  }
