// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
 $primary: #114232;

 $paragraph-color:            														    #646F79 !default;

$paragraph-line-height:                                     2.2 !default;


// The color of the SVG loader should be modified manually.
