.featured-pages-top {
  background: rgba(220,221,222,0.10);
  border-bottom: 1px solid #F6F9FB;
}


// html {
//   position: relative;
//   min-height: 100%;
// }
//
// body {
//   /* Margin bottom by footer height */
//   height: 100%;
//   margin-bottom: 72px;
// }

// .dv-sticky-footer {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   /* Set the fixed height of the footer here */
//   height: 72px;
// }


.post-password-form { /* Our parent item */
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 20px rgba(0,0,0,0.10); /* material style drop shadow */
    padding: 50px 20px;
    border-radius: 4px; /* rounded edges */
    background-size: cover;
    background-position: center;
}

.post-password-required .et_pb_section {
    background-color: transparent; /* it's white by default */
}

.post-password-required .et_pb_button {
    background-color: #C5283D;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    width: 110px;
    margin-top: -14px;
    height: 50px;
    border-radius: 32px; /* rounded buttons */
    transition: .6s ease all; /* color change speed on hover */
}

.post-password-required .et_pb_button:hover {
    background-color: #481D24;
}

.post-password-required .et_pb_button:after {
    display: none;
}

.post-password-required .et_password_protected_form p input {
    width: calc(100% - 120px);
    float: left;
    border-radius: 32px;
    background-color: #fff;
    border: 2px solid #f1f1f1;
    transition: .6s ease all;
}

.post-password-required .et_password_protected_form p input:focus {
    border: 2px solid #481D24; /* thick outline when typing */
}

.single-post .post-password-required .et_post_meta_wrapper:last-child {
    display: none; /* remove weird comments text */
}

.post-password-required .entry-title:before {
    content: "\7e"; /* set padlock icon */
    font-family: 'etModules'; /* set icon font */
    margin-right: 10px;
}

.et_password_protected_form > p {
    margin-bottom: 20px;
}

.page #main-content .post-password-required, .post #main-content .post-password-required {
    max-width: 980px;
    width: 90%;
    margin: 13vw auto;
}
